import React from "react"
import Head from "react-helmet"
// or import Head from next/head for Next.js

const DOMAIN = "https://uex.ai/"
const services = {
    category: "Tech and Software",
    tags: ["UEX", "tech", "software", 'uexio private limited', 'uex private limited', 'uexio pvt ltd','uex pvt ltd' ],
    locations:"India, Bangalore, ahmedabad, Riyadh, jeddah, saudi arabia",
    offerings: {
        design: {
            uiux: "UI, UX, Design, UI/UX Design Services",
            userInterface: "User Interface Design",
            userExperience: "User Experience Design"
        },
        development: {
            web: "web development, Web Development Services, shopify, wordpress, web flow, react, angular, reactjs, nodejs, angularjs, php, dotnet, javascript, typescript, nextjs, htms, css, scss, tailwind, java",
            mobile: "mobile, app, development, Mobile App Development, ios, android, swift, apple, kotlin, flutter, react native, flutter flow",
            arVr: "AR, VR, AR/VR Development",
            customWebsite: "Custom web design, Custom Website Design, Custom Web development, Custom Website Development",
            customApp: "Custom App Development"
        },
        hiring: "Hire a Developer, IT staffing, contract, developer",
        marketing: "Digital Marketing, Digital Marketing Services"
    }
};

export function objectToString(obj) {
    let result = [];
    // Recursive function to handle nested objects
    function recurse(obj) {
        for (let key in obj) {
            if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                recurse(obj[key]); // Recurse for nested objects
            } else if (Array.isArray(obj[key])) {
                result = result.concat(obj[key]); // Add array elements directly
            } else {
                result.push(obj[key]); // Add non-object, non-array values
            }
        }
    }

    recurse(obj);
    return result.join(', ');

}
const MAIN_KEYWORDS =objectToString(services);

const DEFAULT_IMAGE_CARD = "https://uex.ai/Logo.jpeg"
const DEFAULT_TITLE = "UEX"
const DEFAULT_DESCRIPTION = "Redefining User Experience"

const FAVICON_SOURCE = "https://uex.ai/Logo.jpeg"

const POSTFIX_TITLE = " - UEX.ai"

function Helmet({
  title=DEFAULT_TITLE,
  description=DEFAULT_DESCRIPTION,
  link='',
  keywords='',
  imageCard=DEFAULT_IMAGE_CARD,
  largeTwitterCard = false,
  addPostfixTitle = false,
  noIndex = false,
}) {
  let metaTitle

  if (addPostfixTitle) {
    metaTitle = title + POSTFIX_TITLE
  } else {
    metaTitle = title
  }

  const metaDesc = description ?? DEFAULT_DESCRIPTION
  const metaLink = DOMAIN + link

  const metaKeywords = keywords.length
    ? MAIN_KEYWORDS + ", " + keywords
    : MAIN_KEYWORDS

  let metaImageCard

  if (imageCard) {
    if (imageCard.startsWith("https")) {
      metaImageCard = imageCard
    } else {
      metaImageCard = DOMAIN + imageCard
    }
  } else {
    metaImageCard = DEFAULT_IMAGE_CARD
  }

  const metaRobots = noIndex ? "noindex, nofollow" : "index, follow"

  const twitterCardType = largeTwitterCard ? "summary_large_image" : "summary"

  return (
    <Head>
      <html lang="en" />
      <title>{metaTitle}</title>
      <meta name="description" content={metaDesc} />
      <link rel="canonical" href={metaLink} />
      <meta name="keywords" content={metaKeywords} />
      <meta name="robots" content={metaRobots} />
      <link rel="icon" content={FAVICON_SOURCE} />

      {/* OG Tags */}
      {/* https://ogp.me/ */}
      <meta property="og:url" title={metaLink} />
      <meta property="og:title" title={metaTitle} />
      <meta property="og:description" title={metaDesc} />
      <meta property="og:type" content="..." />
      <meta property="og:image" content={metaImageCard} />

      {/* Twitter tags */}
      {/* https://developer.twitter.com/en/docs/twitter-for-websites/cards/guides/getting-started */}
      <meta property="twitter:site" title="twitter username of website" />
      <meta property="twitter:title" title={metaTitle} />
      <meta property="twitter:description" title={metaDesc} />
      <meta
        property="twitter:creator"
        content="twitter username of webpage content"
      />
      <meta property="twitter:card" content={twitterCardType} />
      <meta property="twitter:image" content={metaImageCard} />

      {/* https://moz.com/blog/meta-referrer-tag */}
      <meta name="referrer" content="origin-when-crossorigin" />
    </Head>
  )
}

export default Helmet