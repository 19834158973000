import React from 'react'
import { FiArrowUpRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import styles from './LastContainer.module.scss'

const LastContainer1 = () => {
    const navigate=useNavigate()
    return (
        // <div className={styles.lastContainer1}>
        //     <div className={styles.container1Box}>
        //             <div className='row'>
        //                 <div className='col-12 col-md-7'>
        //                     <h1>Let’s make something great together.</h1>
        //                     <p>When creativity blends with logic, it makes a perfect recipe for success! Your success is our responsibility! Connect with us and we will make your brand an unmatchable one! Regardless of the nature and size of the project, we will deliver the best from the rest!</p>
        //                 </div>
        //                 <div className={`col-12 col-md-5 ${styles.btnContainer}`} >
        //                 <button className={styles.btn_contact} onClick={()=>navigate('/contact')}>Contact <FiArrowUpRight /></button>
        //                 </div>
        //             </div>
        //         </div>
        // </div>
        <div className={styles.lastContainer1}>
            <div className={styles.container2Box}>
                    <div className='row' style={{maxWidth:'900px'}}>
                        <div className='col-12'>
                            <h1>Let’s make something great together.</h1>
                        </div>
                        <div className={`col-12 ${styles.btnContainer}`} >
                        <button className={styles.btn_contact} onClick={()=>navigate('/contact')}>Start a project <FiArrowUpRight /></button>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default LastContainer1