import React from 'react'
import { FiArrowUpRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import styles from './LastContainer.module.scss'

const LastContainer2 = () => {
    const navigate=useNavigate()
    return (
        <div className={styles.lastContainer1}>
            <div className={styles.container2Box}>
                    <div className='row' style={{maxWidth:'680px'}}>
                        <div className='col-12'>
                            <h1>Turn your brand into a great one.</h1>
                        </div>
                        <div className={`col-12 ${styles.btnContainer}`} >
                        <button className={styles.btn_contact} onClick={()=>navigate('/contact')}>Start a project <FiArrowUpRight /></button>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default LastContainer2