import React from 'react'
import { Link } from 'react-router-dom'
import { FaInstagram, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import Logo from "../../../Assets/Logo.svg"
import { navLinks } from "../../Constants/NavLinks.js"
import '../../../globalStyles.scss'
import styles from '../BasicLayout.module.scss'
import { socialLinks } from '../../../Utils/constants.js';


const Footer = () => {

  const addressArray = [
    {
      hash: '#1',
      country: 'SAUDI ARABIA',
      address: '7344, King Abdulaziz Road, Al-Yasmeen District 13325-4783 Riyadh, RAYA7344'
    },
    {
      hash: '#2',
      country: 'INDIA',
      address: '#198, 2nd Floor, CMH Road, Indiranagar, Bangalore, Karnataka, India 560038'
    },
  ]
  return (
    <div className='footer-container'>
      <div className='d-block d-md-block'>
        <div className='footer-segment row align-items-center'>
          <div className='col-12'>
            <div className='row '>
              <div className='col-12 col-md-6'>
                <div className='row g-md-5 g-2'>
                  <div className='col-12'>
                    <img src={Logo} alt='Logo' style={{ height:'65px', marginBottom: '30px' }} />
                  </div>
                  {addressArray?.map((item) => {
                    return (
                      <div className='col-12 col-md-6'>
                        <div className={styles.addressContainer}>
                          <div className={styles.addressHash}><h3>{item?.hash}</h3></div>
                          <div className={styles.addressText}>
                            <h2>{item?.country}</h2>
                            <p style={{ marginBottom: '20px' }}>{item?.address}</p>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className='col-12 col-md-1'></div>
              <div className='col-12 col-md-5'>
                <div className='d-flex align-items-start flex-column h-100'>
                  <div className='row w-100 '>
                    <div className='col-6'>
                      <p className={styles.footerHeadings}>Get in touch</p>
                      {/* <p className={styles.footerContent}>Sales@uex.ai</p> */}
                    <p className={styles.footerContent} style={{fontWeight:'700'}}><a href={`mailto:Hi@uex.ai`}>Hi@uex.ai</a></p>
                    {/* <p className={styles.footerContent}><a href={`tel:+919740670035`}>(+91) 9740670035</a></p>
                    <p className={styles.footerContent}><a href={`tel:+966555655065`}>(+966) 555655065</a></p> */}
                    </div>
                    <div className='col-6'>
                      <p className={`ml-2 ${styles.footerHeadings}`}>Social media</p>
                      <p>
                      {socialLinks?.map((item,index) => {
                        return (
                          <a target="_blank" rel="noreferrer" href={item?.url} style={{ padding:index===0?'5px 0px':'5px 0px 5px 20px', marginTop:'10px' }}>
                            <item.link style={{ color: "#FFFFFF", fontSize: "16px" }} />
                          </a>
                        )
                      })}
                      </p>
                    </div>
                  </div>
                  <div className='row w-100'>
                    <div className='col-12'>
                      <span className={styles.footerHeadings}>Explorer</span>
                    </div>
                    <div className='col-12'>
                      <div className="row pt-4">
                        {navLinks?.map((item) => {
                          return (
                            <div className='col-6 col-sm-3'>
                              <Link className={styles.footerLinks} aria-current="page" to={item?.path}>{item?.title}</Link>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='justify-content-center d-flex mt-4'>
          <span className="footer_rights">Copyright © 2022. uexio pvt. ltd. All rights reserved.</span>
        </div>
      </div>
    </div>
  )
}

export default Footer