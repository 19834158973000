import React from 'react'
import styles from './WorkDetails.module.scss'
import LastContainer1 from '../../Components/LastContainer/LastContainer1'
import BasicLayout from '../../Layout/BasicLayout/BasicLayout'


import { useParams } from 'react-router-dom'
import OtherProjectsCarousel from '../../Components/OtherProjectsCarousel/OtherProjectsCarousel'
import WorkDetailsMobile from './WorkDetailsMobile/WorkDetailsMobile'
import WorkDetailsWeb from './WorkDetailsWeb/WorkDetailsWeb'
import { workArray } from '../../Utils/constants'

const WorkDetails = () => {
    const location = useParams()
    const { workTitle } = location

    const contentData = workArray.find((item) => item?.title === workTitle)
    const otherProjects = workArray.filter((item) => item?.title !== workTitle)
    return (
        <BasicLayout>
            {
                contentData?.type === "mobile" ?
                <WorkDetailsMobile contentData={contentData}/>
                :
                <WorkDetailsWeb contentData={contentData} />
            }
            <div className={`${styles.sectionlast}`}>
                <OtherProjectsCarousel data={otherProjects} />
            </div>
            <LastContainer1 />
        </BasicLayout >
    )
}

export default WorkDetails