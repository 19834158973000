import React from 'react';
import styles from "./WorkDetailSection.module.scss";

const WorkDetailSection = ({item,index}) => (
    <div className={`mainPaddingContainer ${styles.section1}`}>
        <div className='container-fluid m-0 p-0'>
            <div className={`row align-items-center d-flex ${index%2!==0?'flex-sm-row-reverse':""}`}>
                <div className='col-12 col-sm-6 p-0'>
                    <div className={`container ${styles.section1TextContainer}`}>
                        <div className='row m-0'>
                            <h1 className={styles.title}>{item?.title}</h1>
                            <p className={styles.headerDesc}>{item?.description}</p>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-sm-6'>
                    <div className={`d-flex ${index%2===0?'justify-content-end':""}`}>
                    <img src={item?.img} className={styles.headerRightImg} alt='work' />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default WorkDetailSection;
