import React, { useState } from 'react'
import styles from "./ContactUs.module.scss"
import BasicLayout from '../../Layout/BasicLayout/BasicLayout'
import DUBAI from "../../Assets/ContactUs/DUBAI.svg"
import TAJMAHAL from "../../Assets/ContactUs/TAJMAHAL.svg"
import axios from 'axios'
import { TiTick } from "react-icons/ti"
import { IoClose } from "react-icons/io5"

let axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  }
};

const ContactUs = () => {
  const [formData, setFormData] = useState({ name: "", email: "", topic: "", message: "" })
  const [otherTopic, setOtherTopic] = useState()
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [message, setMessage] = useState()
  const formArray = [
    {
      name: "name",
      label: "Your name*",
      placeHolder: "Enter your name",
      type: "text",
    },
    {
      name: "email",
      label: "Your email*",
      placeHolder: "Enter your email",
      type: "email",
    },
    {
      name: "topic",
      label: "Choose topic*",
      placeHolder: "Select one topic",
      type: "select",
      options: [
        { title: "Select one topic", value: "" },
        { title: "Project Managment", value: "Project Managment" },
        { title: "UX/UI Design", value: "UX/UI Design" },
        { title: "Mobile App", value: "Mobile App" },
        { title: "Digital Marketing", value: "Digital Marketing" },
        { title: "Hire a Developer", value: "Hire a Developer" },
        { title: "Web Development", value: "Web Development" },
        { title: "Others", value: "Others" },
      ]
    },
    {
      name: "message",
      label: "Message*",
      placeHolder: "Write your message",
      type: "textarea",
    },
  ]
  const officeArray = [
    {
      location: 'Riyadh, SA',
      hash: '#1',
      address: "7344, King Abdulaziz Road, Al-Yasmeen District 13325-4783 Riyadh, RAYA7344",
      email: 'Hi@uex.ai',
      countryCode: '+966',
      tel: '555655065',
      mapLink: "https://goo.gl/maps/xG1dL8i9iyBUQy8i7",
      backgroundImage: DUBAI,
    },
    {
      location: 'Bangalore, India',
      hash: '#2',
      address: '#198, 2nd Floor, CMH Road, Indiranagar, Bangalore, Karnataka, India 560038',
      email: 'Hi@uex.ai',
      countryCode: '+91',
      tel: '9740670035',
      mapLink: "https://goo.gl/maps/pjQfwVmWfKNNdFp17",
      backgroundImage: TAJMAHAL,
    },

  ]

  const handleChange = (e) => {
    if (e.target.name === 'topic' && e.target.value !== 'Others') {
      setOtherTopic()
    }
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setBtnDisabled(true)
    var finalData = formData
    if (formData?.topic === 'Others') {
      finalData.topic = otherTopic
    }
    axios.post('https://uex.ai/api/v1/send_email_contactus', finalData, axiosConfig)
      .then((res) => {
        setMessage(<> <TiTick className={styles.tick} /> Sent</>)
        setTimeout(() => {
          setMessage()
        }, 5000);
      })
      .catch((err) => {
        setMessage(<> <IoClose className={styles.error}/> {err.message + '. Please try again after some time.' }</>)
      })
    setBtnDisabled(false)
  }
  return (
    <BasicLayout>
      <div className={`${styles.contactUs}`}>
        <div className={`${styles.container1} mainPaddingContainer`}>
          <div className='container-fluid'>
            <div className='row align-items-center'>
              <div className='col-12 col-md-6'>
                <div style={{ maxWidth: "600px" }}>
                  <h3 className={styles.C1_title2} >Let’s start a <span>Project together.</span></h3>
                  <p className={styles.C1_text1}>If you are looking for web design, web development, mobile app, project management, UI/UX, digital marketing solutions, search no more!!  Here’s a one-stop destination! <br /> Reach out to know more!</p>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='row d-flex justify-content-end'>
                  <div className={styles.C1_right_card} style={{ maxWidth: "500px" }}>
                    <div className='row g-4'>
                      <div className='col-12'>
                        <h4 className={styles.formTitle}>Contact Us 👋</h4>
                        <p className={styles.formDesc}>If you have any question or issue’s to use our product. Fill the form below. We’ll help you.</p>
                      </div>
                      <div className='col-12'>
                        <form name='contactForm' onSubmit={handleSubmit}>
                          <div className='row g-4'>
                            {formArray?.map((item) => {
                              return (
                                item?.type === "textarea" ?
                                  <div className='col-12'>
                                    <label for={item?.name} className={styles.formLabel}>{item?.label}</label>
                                    <textarea required className={styles.formInput} id={item?.name} name={item?.name} rows="4" cols="50" value={formData?.[item.name]} onChange={handleChange} placeholder={item?.placeHolder} />
                                  </div>
                                  :
                                  item?.type === "select" ?
                                    <div className='col-12'>
                                      <label for={item?.name} className={styles.formLabel}>{item?.label}</label>
                                      <select required={true} className={styles.formInput} name={item?.name} id={item?.name} value={formData?.[item.name]} onChange={handleChange} placeholder={item?.placeHolder}>
                                        {item?.options?.map((option) => {
                                          return (
                                            <option value={option?.value}>{option?.title}</option>
                                          )
                                        })}
                                      </select>
                                      {formData?.topic === 'Others' ?
                                        <input required className={styles.formInput} style={{ marginTop: '20px' }} type='text' size="100%" id={item?.name} name={item?.name} value={otherTopic} onChange={(e) => setOtherTopic(e.target.value)} placeholder='Enter Topic' />
                                        :
                                        null
                                      }
                                    </div>
                                    :
                                    <div className='col-12 col-md-6'>
                                      <label for={item?.name} className={styles.formLabel}>{item?.label}</label>
                                      <input required className={styles.formInput} type={item?.type} size="100%" id={item?.name} name={item?.name} value={formData?.[item.name]} onChange={handleChange} placeholder={item?.placeHolder} />
                                    </div>
                              )
                            })}
                            <p className={styles.message}>{message}</p>
                            <div className='col-12'>
                              <button disabled={btnDisabled} className={styles.form_btnSubmit} id="submit" value="Submit">Contact Us</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`container-fluid ${styles.container3} mainPaddingContainer`}>
        <div className={`row g-2 g-lg-5 ${styles.C3_row1} align-items-center`}>
          <div className='col-12 col-md-6'>
            <h3 className={styles.C3_title1} style={{ marginBottom: '20px' }}>Our offices</h3>
            <p className={styles.C3_text1}>We have our offices in two of the most amazing cities!</p>
          </div>
          <div className='col-12 col-md-6'>
          </div>
        </div>
        <div className='row g-4 g-md-5'>
          {officeArray?.map((item) => {
            return (
              <div className='col-12 col-md-6'>
                <div className={`${styles.contactCard}`} >
                  <div className={styles.cardBody} style={{ backgroundImage: `url(${item.backgroundImage})` }}>
                    <h4 className={styles.location}>{item?.location}</h4>
                    <h4 className={styles.location}>{item?.hash}</h4>
                    <p className={styles.address}>{item?.address}</p>
                    <p className={styles.email}>Email Us: <a href={`mailto:${item?.email}`}>{item?.email}</a></p>
                    {/* <p className={styles.email}>Tel: <a href={`tel:${item?.tel}`}>{item?.countryCode + " " + item?.tel}</a></p> */}
                    <a className={styles.btnMap} target="_blank" rel="noreferrer" href={item?.mapLink}>Get Direction</a>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </BasicLayout>
  )
}

export default ContactUs