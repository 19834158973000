import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { navLinks } from "../../Constants/NavLinks.js"
import Logo from "../../../Assets/Logo.svg"
import '../../../globalStyles.scss'
import styles from '../BasicLayout.module.scss'


const Header = () => {
  const navigate =useNavigate()

  return (
    <div className={styles.header}>
      <nav className={`navbar navbar-expand-lg navbar-dark fixed-top ${styles.navContainer}`}>
        <div className={styles.header_container} >
          <Link className="navbar-brand" to="/"><img src={Logo} alt="logo" style={{ padding: "15px", height: "80px" }} /></Link>
          <button className={`navbar-toggler ${styles.btn_toggler}`} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${styles.navbar}`} id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
            <li className="nav-item d-flex align-items-center p-3">
                    <Link className={`nav-link ${styles.header_link} ${window.location.pathname === '/' ? styles.link_active : styles.link_inactive}`} aria-current="page" to='/'>Home</Link>
                  </li>
              {navLinks?.map((item) => {
                return (
                  <li className="nav-item d-flex align-items-center p-3">
                    <Link className={`nav-link ${styles.header_link} ${`/${window.location.pathname.split('/')[1]}` === item?.path ? styles.link_active : styles.link_inactive}`} aria-current="page" to={item?.path}>{item?.title}</Link>
                  </li>
                )
              })}
            </ul>
            <div className="nav-item ">
              <div className='d-flex'><button className={`nav-item  btn ${styles.btn_getStarted}`} onClick={()=>navigate('/contact')}>Let's Talk</button></div>
            </div>
          </div>
        </div>
      </nav >
    </div >
  )
}

export default Header