import React, { useRef } from 'react'
import styles from "./OtherProjectsCarousel.module.scss"
import quote from "../../Assets/Home/quote.svg"
import Slider from 'react-slick/lib/slider'
import { useNavigate } from 'react-router-dom'
import { FiArrowUpRight } from 'react-icons/fi'



const OtherProjectsCarousel = ({ data }) => {
    const sliderRef2 = useRef()
    const navigate = useNavigate()

    const settings = {
        dots: false,
        slidesToShow: 2.6,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: false,
        centerPadding: "20px",
        autoplay: true,
        speed: 500,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1.1,
                    slidesToScroll: 1
                }
            }
        ]

    };
    return (
        <div className={`container-fluid m-0 p-0 ${styles.commentCarousel}`}>
            <div className={`mainPaddingContainer ${styles.container1}`}>
                <div className='row'>
                    <div className='col-12 col-sm-6'>
                        <h2 className={styles.title}>Other Projects</h2>
                    </div>
                    <div className={`col-12 col-sm-6 ${styles.btnContainer}`}>
                        <button className={`btn_medium mobHidden`} onClick={() => navigate('/work')}>See All <FiArrowUpRight /></button>
                    </div>
                </div>
            </div>

            <div className={styles.sliderContiner}>
                <Slider ref={sliderRef2} {...settings}>
                    {
                        data?.map((item) => {
                            return (
                                <div>
                                    <div className={styles.cardContainer} >
                                        <div className={`container ${styles.containerBackbround}`} style={{ backgroundImage: `url(${item?.otherprojectImg})` }}>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <img src={item?.icon} alt="companyIcon" />
                                                    <h2 className={styles.companyName}>{item?.title}</h2>
                                                    <button className={styles.btn_more} onClick={() => navigate(`/work/${item.title}`)}>View Case Study <FiArrowUpRight /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
            </div>
            <div className={` ${styles.btnContainer}`}>
                <button className={`btn_medium mobShow`} onClick={() => navigate('/work')}>See All <FiArrowUpRight /></button>
            </div>

        </div>
    )
}

export default OtherProjectsCarousel