import React from 'react'
import styles from './WorkDetailsWeb.module.scss'

const WorkDetailsWeb = ({ contentData }) => {
    return (
        <>
            <div className={`mainPaddingContainer ${styles.headerContainer}`} style={{ backgroundImage: `url(${contentData?.backgroundImg})`, backgroundSize: `${contentData?.backgroundSize}`, backgroundPosition: `${contentData?.backgroundPosition}` }}>
                <div className='container-fluid'>
                    <div className='row align-items-center justify-content-center'>
                        <img src={contentData?.icon} className={styles.headerIcon} style={{ height: '60px', width: 'auto' }} alt='workIcon' />
                        <h1 className={styles.headerTitle}>{contentData?.title}</h1>
                        <p className={styles.headerDesc}> {contentData?.description}</p>
                        {contentData.image && <img src={contentData.image} className={styles.headerImg} alt='header' />}
                    </div>
                </div>
            </div>
            <div className={` mainPaddingContainer ${styles.section1} `}>
                <div className='container-fluid'>
                    <div className='row'>
                        {/* <div className='col-12 col-sm-6'>
                            <h1 className={styles.title}>{contentData?.section1?.title}</h1>
                        </div> */}
                        <div className='col-12 col-sm-12'>
                            <div className={styles.section1TextContainer}>
                                <h1 className={styles.title}>Our Role</h1>
                                <p className={styles.text1}>{contentData?.section1?.ourRole}</p>
                                {/* <h4 className={styles.label}>Company</h4> */}
                                {/* <p className={styles.text1}>{contentData?.section1?.company}</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`mainPaddingContainer ${styles.section2}`}>
                <img src={contentData?.section2?.img} />
            </div>
            <div className={`mainPaddingContainer ${styles.section3}`}>
                <div className='row justify-content-center'>
                    {contentData?.section3?.title && <h1 className={styles.title}>{contentData?.section3?.title}</h1> }
                    <p className={styles.desc}>{contentData?.section3?.description}</p>
                    <img src={contentData?.section3?.img} />
                </div>
            </div>
            {contentData?.section4?.imgRight && contentData?.section4?.imgLeft &&
                <div className={`mainPaddingContainer ${styles.section4}`}>
                    <div className='row justify-content-center'>
                        <h1 className={styles.title}>{contentData?.section4?.title}</h1>
                        <p className={styles.desc}>{contentData?.section4?.description}</p>
                        <div className='row align-items-center'>
                            <div className='col-12 col-sm-7'><img src={contentData?.section4?.imgLeft} /></div>
                            <div className='col-12 col-sm-5'><img src={contentData?.section4?.imgRight} /></div>
                        </div>
                    </div>
                </div>
            }
            {!contentData?.section4?.imgRight && contentData?.section4?.imgLeft &&
                <div className={` ${styles.section4_2}`}>
                    <div className={`mainPaddingContainer`}>
                        <div className='row justify-content-center'>
                            <h1 className={styles.title}>{contentData?.section4?.title}</h1>
                            <p className={styles.desc}>{contentData?.section4?.description}</p>
                        </div>
                    </div>
                    <div className={styles.sectionImg}>
                        <img src={contentData?.section4?.imgLeft} />
                    </div>
                </div>
            }
            <div className={styles.sectionImg}>
                <img src={contentData?.imgSection?.img} />
            </div>
        </>
    )
}

export default WorkDetailsWeb