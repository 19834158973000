import AboutPage from "../../Pages/AboutPage/AboutPage";
import ContactUs from "../../Pages/ContactUs/ContactUs";
import Services from "../../Pages/Services/Services";
import Work from "../../Pages/Work/Work";


export const navLinks = [
    {
        title: 'About',
        path: "/about",
        exact: true,
        element: <AboutPage />
    },
    {
        title: 'Services',
        path: "/services",
        exact: true,
        element: <Services />
    },
    {
        title: 'Work',
        path: "/work",
        exact: true,
        element: <Work />
    },
    {
        title: 'Contact Us',
        path: "/contact",
        exact: true,
        element: <ContactUs />
    },
   
]