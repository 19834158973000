import React from 'react'
import styles from './WorkDetailsMobile.module.scss'
import WorkDetailSection from '../../../Components/WorkDetailSection'

const WorkDetailsMobile = ({contentData}) => {
    return (
        <>
            <div className={`${styles.headerContainer}`} style={{ backgroundImage: `url(${contentData?.backgroundImg})`, backgroundSize:`${contentData?.backgroundSize}`, backgroundPosition:`${contentData?.backgroundPosition}` }}>
                <div className='container-fluid'>
                    <div className='row align-items-center'>
                        <div className='col-12 col-sm-6 p-0'>
                            <div className={`container ${styles.headerTextContainer}`}>
                                <div className='row m-0'>
                                    <img src={contentData?.icon} className={styles.headerIcon} style={{ height: '60px', width: 'auto' }} alt='workIcon' />
                                    <h1 className={styles.headerTitle}>{contentData?.title}</h1>
                                    <p className={styles.headerDesc}> {contentData?.description}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 d-flex justify-content-center'>
                            <img src={contentData.image} className={styles.headerRightImg} style={contentData?.imageStyles} alt='header' />
                        </div>
                    </div>
                </div>
            </div>
            <div className={` mainPaddingContainer ${styles.section1} `}>
                <div className='container-fluid'>
                    <div className='row'>
                        {/* <div className='col-12 col-sm-6'>
                            <h1 className={styles.title}>{contentData?.section1?.title}</h1>
                        </div> */}
                        <div className='col-12 col-sm-12'>
                            <div className={styles.section1TextContainer}>
                                <h1 className={styles.title}>Our Role</h1>
                                <p className={styles.text1}>{contentData?.section1?.ourRole}</p>
                                {/* <h4 className={styles.label}>Company</h4> */}
                                {/* <p className={styles.text1}>{contentData?.section1?.company}</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {contentData?.sections?.map((section, index) => {
                return (
                    <WorkDetailSection item={section} index={index} />
                )
            })}
            <div className={styles.sectionImg}>
                <img src={contentData?.imgSection?.img} alt='lastImage'/>
            </div>
</>
    )
}

export default WorkDetailsMobile