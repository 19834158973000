import React from "react";
import styles from "./OurClients.module.scss";
import logo1 from "../../Assets/about/logo1.svg";
import logo2 from "../../Assets/about/logo2.svg";
import logo3 from "../../Assets/about/logo3.svg";
import logo4 from "../../Assets/about/logo4.svg";
import logo5 from "../../Assets/about/logo5.svg";
import logo6 from "../../Assets/about/logo6.svg";
import logo7 from "../../Assets/about/logo7.svg";
import logo8 from "../../Assets/about/logo8.svg";

export const brandsArray = [
  { icon: logo1 },
  { icon: logo2 },
  { icon: logo3 },
  { icon: logo4 },
  { icon: logo5 },
  { icon: logo6 },
  { icon: logo7 },
  { icon: logo8 },
];

const OurClients = ({ showNumebr=false }) => {
  return (
    <>
      <div className="row">
        <p className={styles.companyColabText}>
          {showNumebr ? "100+ Clients" : "Our Clients"}
        </p>
      </div>
      <div className={`${styles.brandContainer}`}>
        {brandsArray?.map((brand) => {
          return (
            <div className={`${styles.brandImageContainer}`}>
              <img src={brand?.icon} alt="brandImage" />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default OurClients;
