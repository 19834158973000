import React from 'react'
import styles from './Services.module.scss'
import BasicLayout from '../../Layout/BasicLayout/BasicLayout'
import ServiceCard from '../../Components/ServiceCard/ServiceCard'
import services from "../../Assets/Service/service.png"
import { useNavigate } from 'react-router-dom'
import { FiArrowUpRight, FiArrowRight } from "react-icons/fi";
import Accordian from '../../Components/Accordian/Accordian'
import CommentsCarousel from '../../Components/CommentsCarousel/CommentsCarousel'
import LastContainer2 from '../../Components/LastContainer/LastContainer2'
import { serviceArray } from '../../Utils/constants'


const Services = () => {
  const navigate = useNavigate()
  const summaryDetails = [
    { count: '4+', title: 'Years Experience' },
    { count: '100+', title: 'Projects' },
    { count: '100+', title: 'Clients' },
    { count: '10+', title: 'Domains' },
  ]
  const FAQsArray = [
    {
      title: "What makes you so special?",
      text: [
        { text: "We, at UEX, treat each of our projects with utmost care and give it a creative touch and that is what makes us special! UEX is all about making your experience with us a unique and memorable one!" }
      ]
    },
    {
      title: "Who are you guys, anyway?",
      text: [
        { text: "We are a super-creative team of developers who are highly enthusiastic about our work and have made our client’s priority as our priority! We believe that our uniqueness lies in our work and our approach towards each project." }
      ]
    },
    {
      title: "What happens if I wait too long?",
      text: [
        { text: "You would be missing out on a great opportunity if you wait too long to make a decision, but as the saying goes, better late than never! Time is a very important factor for every project, so you don’t want to delay in making a quintessential decision of choosing the right team for your project." }
      ]
    }
  ]
  return (
    <BasicLayout>
      <div className='mainPaddingContainer'>
        <div className={styles.homeContainer} >
          <div className={`${styles.container1} row align-items-end`}>
            <div className='col-12 col-lg-7'>
              <h1 className={styles.heading}>Creative with logic to build <span>Great Products</span> </h1>
              <p className={styles.text} >Having served more than 50 customers and completed more than 50 projects, we understand what it takes to build great products! Our synergistic approach and unique sense of creativity with logic allows us to think out of the box and that is what drives us to deliver the best from the rest!!</p>
            </div>
            <div className={`col-12 col-lg-5 ${styles.btnContainer}`}>
              <button className={`btn_medium mobHidden`} onClick={() => navigate('/work')}>View our works <FiArrowUpRight /></button>
            </div>
          </div>
          <div className='row'>
            <img src={services} alt='img'/>
          </div>
          <div className={`row ${styles.summary}`}>
            <div className='col-12 col-md-3'>
              <p className={styles.summaryHeading}>Our Summary <br />in Number <FiArrowRight /></p>
            </div>
            <div className='col-12 col-md-9'>
              <div className='row'>
                {summaryDetails?.map((summary) => {
                  return (
                    <div className='col-6 col-md-3'>
                      <p className={styles.summaryCount}>{summary?.count}</p>
                      <p className={styles.summaryTitle}>{summary?.title}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.container2}>
        <div className='mainPaddingContainer'>
          <div className={styles.services}>
            <div className={styles.serviceTitle}>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <h1 className={styles.heading1}>What services do we <span>offer</span>?</h1>
                  <p className={styles.text1}>Mobile design is our forte and we take pride in each of our solutions, regardless of the size of the project.  Our passion to provide the best service to our clients ensures that each service we offer comes with an inspiration that will surpass your imagination.</p>
                </div>
                {/* <div className={`col-12 col-md-6 ${styles.btnContainer}`}>
                <button className={`${styles.btn_aboutUs} mobHidden`}>Our Services <FiArrowUpRight /></button>
              </div> */}
              </div>
            </div>
            <div className="row g-4 g-md-5">
              {serviceArray?.map((service) => {
                return (
                  <div className={`col-12 col-md-6`}>
                    <ServiceCard item={service} />
                  </div>
                )
              })}
            </div>
            {/* <div className={`${styles.btnContainer}`}>
              <button className={`${styles.btn_knowMore} mobShow`}>Our Services <FiArrowUpRight /></button>
            </div> */}
          </div>
        </div>
      </div>
      <div className={`container-fluid ${styles.container3}`}>
        <div className='mainPaddingContainer'>
          <div className='row g-3 g-sm-5'>
            <div className='col-12 col-sm-5'>
              <h3 className={`${styles.heading1}`}>Most Frequently Asked <span>Questions</span></h3>
            </div>
            <div className='col-12 col-sm-1'></div>
            <div className='col-12 col-sm-6'>
              <div className="accordion accordion-flush" id="accordionFlushExample">
                {FAQsArray?.map((item, index) => {
                  return (
                    <Accordian item={item} index={index} />
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <CommentsCarousel /> */}
      <LastContainer2/>
    </BasicLayout>
  )
}

export default Services